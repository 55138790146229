import React, {Fragment} from "react"

import '../../scss/selectable-list.scss'

import Selectable from '../selectable'



class SelectableList extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
    return(
    <div className="selectable--list--container">
            {
              this.props.selectablesList.map((text, i) => {
                return(<Selectable key={i} text={text} connectIcon={this.props.connectIcon} />)
              }, this) 
            }
    </div>
    );
    }
}

export default SelectableList;
